import React from "react";
import news1 from "../images/news/1-370x370.jpg";
import news2 from "../images/news/2-370x370.jpg";
import news3 from "../images/news/3-370x370.jpg";
const LatestNews = () => {
  return (
    <div className="md:mx-20 mx-10">
      <div className="flex justify-between">
        <div className="font-bold text-4xl">Latest News</div>
        <div className="font-bold text-lg text-textYellow">All Articles</div>
      </div>

      {/* contet start  */}

      <div className="flex md:flex-row flex-col justify-evenly md:my-10 my-5 ">
        <div class="relative ">
          <a class="absolute  inset-0 z-10 border-textYellow border-2 bg-white text-center h-full w-full flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
            <div className="md:mx-10 mx-5 text-justify gap-10 flex flex-col">
              <small class="tracking-wider">Des-15-2016</small>
              <div className="font-bold text-3xl ">
                Discover Architecture Of Bario
              </div>
              <p class="" data-aos="fade-left">
                Lorem ipsum dolor sit amet, consect etur adipiscing elit. Mau
                ris vel auctorol est. Integer nunc ipsum...
              </p>
              <small className="font-bold text-textYellow">Continue</small>
            </div>
          </a>
          <a href="#" class="relative">
            <div class="w-[400px] h-[400px]  flex flex-wrap content-center">
              <img src={news1} class="mx-auto  " alt="" />
            </div>
          </a>
        </div>
        <div class="relative ">
          <a class="absolute  inset-0 z-10 border-textYellow border-2 bg-white text-center h-full w-full flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
            <div className="md:mx-10 mx-5 text-justify gap-10 flex flex-col">
              <small class="tracking-wider">Des-15-2016</small>
              <div className="font-bold text-3xl ">
                Discover Architecture Of Bario
              </div>
              <p class="" data-aos="fade-left">
                Lorem ipsum dolor sit amet, consect etur adipiscing elit. Mau
                ris vel auctorol est. Integer nunc ipsum...
              </p>
              <small className="font-bold text-textYellow">Continue</small>
            </div>
          </a>
          <a href="#" class="relative">
            <div class="w-[400px] h-[400px]  flex flex-wrap content-center">
              <img src={news2} class="mx-auto  " alt="" />
            </div>
          </a>
        </div>
        <div class="relative ">
          <a class="absolute  inset-0 z-10 border-textYellow border-2 bg-white text-center h-full w-full flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
            <div className="md:mx-10 mx-5 text-justify gap-10 flex flex-col">
              <small class="tracking-wider">Des-15-2016</small>
              <div className="font-bold text-3xl ">
                Discover Architecture Of Bario
              </div>
              <p class="" data-aos="fade-left">
                Lorem ipsum dolor sit amet, consect etur adipiscing elit. Mau
                ris vel auctorol est. Integer nunc ipsum...
              </p>
              <small className="font-bold text-textYellow">Continue</small>
            </div>
          </a>
          <a href="#" class="relative">
            <div class="w-[400px] h-[400px]  flex flex-wrap content-center">
              <img src={news3} class="mx-auto  " alt="" />
            </div>
          </a>
        </div>

       
      </div>

      {/* contet END  */}
    </div>
  );
};

export default LatestNews;
