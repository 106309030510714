import React from "react";
import D1  from '../images/d-1.jpg'
import D2  from '../images/d-2.jpg'
const Deal = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col md:flex-row flex-wrap gap-10 items-center">
        <div>
          <div className="flex flex-col md:px-2 px-5  gap-10 md:w-[500px] md:h-[400px] w-96 h-96 justify-center">
            <div className=" divide-x-8  divide-blue-800   ">
              <h1 className="divide-x-4  divide-red-700"> </h1>
              <h1 className="font-bold text-4xl  mx-2">
                Very Good Deal For House{" "}
              </h1>
            </div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </div>
            <div>
              <button
                className="border rounded-xl md:px-5 px-2 py-2 text-blue-600
             border-blue-500  hover:bg-blue-500 hover:text-white"
              >
                Get A Quote
              </button>
            </div>
          </div>
          <div></div>
        </div>
        <div>

            <img src={D1} alt="" />
        </div>
        <div>

        <img src={D2} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Deal;
