/* eslint-disable no-unused-vars */
import { BrowserRouter  ,  Routes,Route} from "react-router-dom"
import Realestate from "./Realestate";
import Arcitecture from "./Arcitecture/Arcitecture";
import Hotels from "./Hotels/Hotels";
import Propertie from "./Properties/Propertie";
import Notfound from "./Notfound";




function App() {
  return (
    <div className="">

    <BrowserRouter>
      <Routes>

        <Route path="/" element={<Realestate/>}/>
        <Route path="/architectural-design" element={<Arcitecture/>}/>
        <Route path="/book-luxury-hotel-online" element={<Hotels/>}/>
        <Route path="/property-dealing" element={<Propertie/>}/>
        <Route path="*" element={<Notfound />} />
    
</Routes>
</BrowserRouter>
    </div>
  );
}

export default App;
