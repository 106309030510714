import React from "react";

const Forms = () => {
  return (
    <div>
      <div>
        <div className="  ">
          <div className="flex  md:flex-row flex-col text-center ">
            <div className="bg-black text-white md:py-4 h-full pl-3">
              CHECK <br />
              AVAILABILITY
            </div>
            <div className=" bg-orange-200 md:py-4 w-full pr-3 ">
              <div className="flex justify-between md:flex-row flex-col gap-5 text-center">
                {/* Arrival Date : start  */}
                <div className="flex gap-2 justify-evenly md:flex-row flex-col mx-5 items-center">
                  <div>Arrival Date :</div>
                  <div className="">
                    <input
                      type="date"
                      className="md:w-36 w-80 px-3 py-2 rounded-lg "
                    />
                  </div>
                </div>
                {/* Arrival Date : END   */}
                {/* Departure Date : : start  */}
                <div className="flex gap-2 justify-evenly md:flex-row flex-col mx-5 items-center">
                  <div>Departure Date :</div>
                  <div>
                    <input
                      type="date"
                      className="md:w-36 w-80 px-3 py-2 rounded-lg"
                    />
                  </div>
                </div>
                {/* Departure Date :   */}
                {/* Adults: Date : : start  */}
                <div className="flex gap-2 justify-evenly md:flex-row flex-col mx-5 items-center">
                  <div>Adults:</div>
                  <div>
                    <select
                      name=""
                      id=""
                      className="md:w-36 w-80 px-3 py-2 rounded-lg"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                {/* Departure Date :   */}
                {/* Children:: Date : : start  */}
                <div className="flex gap-2 justify-evenly md:flex-row flex-col mx-5 items-center">
                  <div>Children:</div>
                  <div>
                    <select
                      name=""
                      id=""
                      className="md:w-36 w-80 px-3 py-2 rounded-lg"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                {/* Departure Date :   */}
                {/* button start  */}
                <div className="bg-white text-black w-fit p-2 rounded-md">
                  <div className=" flex md:justify-normal justify-center">
                    CHECK
                  </div>
                </div>
                {/* button END  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forms;
