/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import Image1 from '../images/slider/slide1.jpg'
import Image2 from '../images/slider/slide2.jpg'
import Image3 from '../images/slider/slide3.jpg'

const Home = () => {
  return (
    <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center
     md:flex md:px-8">
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide className="relative">
          <img
            className="object-fill w-full md:h-[600px] h-96"
            src={Image1}
            alt="image slide 1"
          />
           <div  data-aos="fade-up" class=" border-2 md:p-10 p-5
            border-yellow-300 absolute    text-5xl text-white md:top-[200px] md:left-[300px] top-[150px] left-[150px] -translate-x-1/2 -translate-y-1/2">
          <small className="text-lg" >Residential</small> <br />

          <p className="font-bold md:text-5xl text-xl">   The luxury <br /> Residency in <br />  forest  </p> <br />

          <small className="text-lg">see project </small>
           </div>
        </SwiperSlide>
        <SwiperSlide className="relative">
          <img
            className="object-fill w-full md:h-[600px] h-96"
            src={Image2}
            alt="image slide 1"
          />
           <div  data-aos="fade-up" class=" border-2 md:p-10 p-5 border-yellow-300 absolute    text-5xl text-white md:top-[200px] md:left-[300px] top-[150px] left-[150px] -translate-x-1/2 -translate-y-1/2">
          <small className="text-lg">Home</small> <br />

          <p className="font-bold md:text-5xl text-xl"> Smail House <br /> Never Wroclaw    </p> <br />

          <small className="text-lg">see project </small>


           
           </div>
        </SwiperSlide>
        <SwiperSlide className="relative">
          <img
            className="object-fill w-full md:h-[600px] h-96"
            src={Image3}
            alt="image slide 1"
          />
           <div  data-aos="fade-up" class=" border-2 md:p-10 p-5 border-yellow-300 absolute    text-5xl text-white md:top-[200px] md:left-[300px] top-[150px] left-[150px] -translate-x-1/2 -translate-y-1/2">
          <small className="text-lg">Home</small> <br />

          <p className="font-bold md:text-5xl text-xl">   The luxury <br /> Residency in <br /> the forest  </p> <br />

          <small className="text-lg">see project </small>


           
           </div>
        </SwiperSlide>
      
      </Swiper>
    </div>
  );
};

export default Home;
