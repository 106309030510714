import React from "react";
import clinet from '../images/client.jpg'
const Customer = () => {
  return (
    <>
      <div className="md:my-10 my-5">
        <div>
          {/* Heders start  */}
          <div className="flex flex-col items-center md:mx-2 mx-5">
            <div className=" divide-x-8  divide-blue-800   ">
              <h1 className="divide-x-4  divide-red-700"> </h1>
              <h1 className="font-bold text-4xl  mx-2">
                What is Says Our Customer{" "}
              </h1>
            </div>
          </div>

          {/* Heders END  */}

          <div className="">
<div className=" flex justify-center">


            <div className=" border-2 border-black md:px-10 px-5 md:py-10 py-5  flex flex-col md:flex-row md:my-20 my-10 flex-wrap items-center justify-center gap-20">
              <div>
                <img src={clinet} alt="" className="w-[100px] h-[100px]" />
              </div>
              <div>
                <div>
                  Majority <hr className="w-20 h-5" />
                </div>
                <div>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected hu
                </div>
              </div>
            </div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
