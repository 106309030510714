import React from "react";
import hotel_logo from "../images/hotel_logo.png";
const Navbar = () => {
  return (
    <div className=" bg-black md:h-12 h-12 ">
      <div className="md:mx-10 mx-5   ">
        <div class="flex justify-between py-3">
          <div class="flex md:justify-normal justify-center ">
            <img src={hotel_logo} alt="" />
          </div>
          <div className="md:block hidden">
            <div class="flex gap-10 list-none text-white text-center ">
              <li>HOME</li>
              <li>ACCOMODATION</li>
              <li>PAGES</li>
              <li>NEWS & DEALS</li>
              <li>WORLD OF FLAWLES</li>
              <li>CONTACT</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
