import React from "react";
import About1 from '../images/about-img.jpg';
const About = () => {
  return (
    <div className="flex justify-center ">
      <div className="flex justify-between md:flex-row flex-col md:my-10 my-5 gap-20 max-w-[1280px]">
        <div>
          <img src={About1} alt=""  className="md:w-[500px] md:h-[400px] w-96 h-80 md:px-2 px-5 "/>
        </div>
        <div  className="flex flex-col md:px-2 px-5  gap-10 md:w-[450px] md:h-[400px] w-96 h-96 justify-center">
       <div className=" divide-x-8  divide-blue-800   "> 
          <h1 className="divide-x-4  divide-red-700"> </h1>     
          <h1 className="font-bold text-4xl  mx-2">About Our Apartment </h1> 
          </div>
        <div>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words
          </div>
          <div>
            <button className="border rounded-xl md:px-5 px-2 py-2 text-blue-600 border-blue-500  hover:bg-blue-500 hover:text-white">Read More</button>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default About;
