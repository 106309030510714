import React from "react";
import deluxe from "../images/Deluxe_room.png";
import luxury from "../images/luxury_room.png";
import elegant from "../images/elegant_room.png";
const Contet = () => {
  return (
    <>
    
   
    <div className="flex justify-center md:my-10 my-5  ">
      <div className="max-w-[1280px] md:mx-5 mx-10 ">
        <div className="flex justify-around gap-20 md:my-10 my-5 md:flex-row flex-col-reverse">
          {/* image Start  */}

          <div>
            <img
              src={deluxe}
              alt=""
              className="md:w-[600px] md:h-[400px] w-80 h-80object-cover"
            />
          </div>
          {/* image end  */}
          {/* content start  */}
          <div className=" md:w-[600px] md:h-[400px] w-80 h-80text-justify">
            <div className="font-bold text-2xl md:my-5 my-4">DELEUXE ROOM</div>
            <div>
              Deluxe rooms, are modern decorated, can accommodate up to 2
              persons, totally soundproofed and equipped with high tech comforts
              such as high speed internet access, USB ports , smart TV, room
              cleaning touch system and private hydromassage bathtub.
              <br />
              <br />
              <div className="font-bold text-2xl md:my-5 my-4">
                {" "}
                ROOM FACILITIES{" "}
              </div>
              Free internet , Coffee / Tea maker , Soundproof walls ,
              Individually air conditioning system , Smart TV with satellite
              channels , Electronic safe box , Branded bathroom amenities ,
              Bathrobe and slippers , Hair dryer , Weighting scale , Gym
              dumbbells , Mini bar , Daily maid services , Hydromassage bathtub
              ,
            </div>
          </div>
          {/* content end  */}
        </div>
        <div className="flex justify-around gap-20 md:flex-row flex-col">
          {/* content start  */}
          <div className=" md:w-[600px] md:h-[400px] w-80 h-80text-justify">
            <div className="font-bold text-2xl md:my-5 my-4">ELEGANT ROOM</div>
            <div>
              270 Sq Ft Spacious Rooms with 42 Inch Led TV with Premium
              satellite channels, Tea Coffee Maker, Split Air Conditioner, King
              Size Comfortable Bed With Spring Mattress, Sofa cum bed ,
              Intercom, Modern Bathroom Amenities, Large Common Balcony With
              Sitting
              <br />
              <br />
              <div className="font-bold text-2xl md:my-5 my-4">
                {" "}
                ROOM FACILITIES
              </div>
              Air conditioning , Desk , Dining area , Housekeeping , Electric
              kettle , Cable / satellite TV , Extra long beds , Bath / shower ,
              Room service , Safe , Seating area , Telephone , Wardrobe /
              wardrobe , Bottled water , Private bathrooms , Tile / marble floor
              , Wake-up service / alarm clock , Flatscreen TV , Complimentary
              toiletries ,
            </div>
          </div>
          {/* content end  */}
          {/* image Start  */}

          <div>
            <img
              src={elegant}
              alt=""
              className="md:w-[600px] md:h-[400px] w-80 h-80object-cover"
            />
          </div>
          {/* image end  */}
        </div>
        <div className="flex justify-around md:flex-row flex-col-reverse gap-20 md:my-10 my-5">
          {/* image Start  */}

          <div>
            <img
              src={luxury}
              alt=""
              className="md:w-[600px] md:h-[400px] w-80 h-80object-cover"
            />
          </div>
          {/* image end  */}
          {/* content start  */}
          <div className=" md:w-[600px] md:h-[400px] w-80 h-80text-justify">
            <div className="font-bold text-2xl md:my-5 my-4">LUXURY ROOM</div>
            <div>
              A place of rest and relaxation, your bedroom should be your solace
              from the hustle and bustle of everyday life. Sure, you want your
              bedroom’s style to reflect your design preferences, but you also
              want to make sure it functions as the retreat you deserve.
              Transitioning a bedroom from drab to luxurious can be as simple as
              the addition of new lamps or maybe a bit more complex, like an
              intricate accent wall. If you just can’t put your finger on what
              yours needs though, no need to worry because that is exactly what
              we’re here for! Our collection features the best luxury bedrooms
              with photos and tips, so you can finally find that perfect design
              <br />
              <br />
            </div>
          </div>
          {/* content end  */}
        </div>
      </div>


    
    </div> 
    
    <div className="bg-slate-200 md:py-10 py-5">
        <div className="text-center max-w-[1280px] md:mx-5 mx-10">

       
        <div className="text-justify font-bold text-xl">
        Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia. Phasellus accumsan urna
         vitae molestie interdum. Nam sed placerat libero, non eleifend dolor magna.
        </div>
        <div className="font-bold text-xl text-orange-600">
        Lire plus, CEO & Founder
        </div>
        </div>
      </div>
    
    </>
  );
};

export default Contet;
