import React from "react";

const Pricing = () => {
  return (
    <div>
      <div>
        {/* Heders start  */}
        <div className="flex flex-col items-center md:mx-2 mx-5">
          <div className=" divide-x-8  divide-blue-800   ">
            <h1 className="divide-x-4  divide-red-700"> </h1>
            <h1 className="font-bold text-4xl  mx-2">Our Pricing </h1>
          </div>
          <div>
            
          </div>
          <div>
            There are many variations of passages of Lorem Ipsum available, but
            the
          </div>
        </div>

        {/* Heders END  */}
        <div className="flex justify-center ">
          <div className="max-w-[1280px] flex justify-center md:flex-row flex-col flex-wrap md:my-5 my-5 gap-10">
            <div className=" md:mx-0 mx-5">
              <div className="bg-blue-500 md:w-[400px] w-96">
                <div className="bg-blue-900 flex flex-col gap-2 items-center justify-center font-bold text-lg text-white md:py-5 py-3 ">
                  <div>O1</div>
                  <div>Basic</div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3 md:py-5 py-3 font-bold text-lg text-white">
                  <div className="text-4xl">$1000.00</div>
                  <div>
                    {" "}
                    <hr className="w-20 text-5xl" />{" "}
                  </div>
                  <div>variations of</div>
                  <div> passages of Lorem</div>
                  <div> Ipsum available,</div>
                  <div> but the majority</div>
                  <div> have suffered</div>
                  <div> alteration in</div>
                </div>
              </div>
              <div className="items-center justify-center flex flex-col ">
                <button className="bg-blue-900 text-white md:px-10 px-5  py-2 md:my-10 my-5 rounded-lg">
                  Buy Now{" "}
                </button>
              </div>
            </div>
            <div className="md:mx-0 mx-5">
              <div className="bg-blue-500 md:w-[400px] w-96 ">
                <div className="bg-blue-900 flex flex-col gap-2 items-center justify-center font-bold text-lg text-white md:py-5 py-3 ">
                  <div>O2</div>
                  <div>STANDARD</div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3 md:py-5 py-3 font-bold text-lg text-white">
                  <div className="text-4xl">$2000.00</div>
                  <div>
                    {" "}
                    <hr className="w-20 text-5xl" />{" "}
                  </div>
                  <div>variations of</div>
                  <div> passages of Lorem</div>
                  <div> Ipsum available,</div>
                  <div> but the majority</div>
                  <div> have suffered</div>
                  <div> alteration in</div>
                </div>
              </div>
              <div className="items-center justify-center flex flex-col ">
                <button className="bg-blue-900 text-white md:px-10 px-5  py-2 md:my-10 my-5 rounded-lg">
                  Buy Now{" "}
                </button>
              </div>
            </div>
            <div className="md:mx-0 mx-5">
              <div className="bg-blue-500 md:w-[400px] w-96 ">
                <div className="bg-blue-900 flex flex-col gap-2 items-center justify-center font-bold text-lg text-white md:py-5 py-3 ">
                  <div>O3</div>
                  <div>PREMIUM</div>
                </div>
                <div className="items-center justify-center flex flex-col gap-3 md:py-5 py-3 font-bold text-lg text-white">
                  <div className="text-4xl">$3000.00</div>
                  <div>
                    {" "}
                    <hr className="w-20 text-5xl" />{" "}
                  </div>
                  <div>variations of</div>
                  <div> passages of Lorem</div>
                  <div> Ipsum available,</div>
                  <div> but the majority</div>
                  <div> have suffered</div>
                  <div> alteration in</div>
                </div>
              </div>
              <div className="items-center justify-center flex flex-col ">
                <button className="bg-blue-900  text-white md:px-10 px-5  py-2 md:my-10 my-5 rounded-lg">
                  Buy Now{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
