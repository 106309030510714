import React, { useState } from "react";
import brand from "../images/brand.png";
import { Link } from "react-router-dom";
import {
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  return (
    <div>
      <nav className="w-full ">
        <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <a href="javascript:void(0)">
                <div className="flex gap-5">
                  <div className="mt-2">
                    <img src={brand} alt="Logo" />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold text-black">Cerbosys</h2>
                    <small>architecture</small>
                  </div>
                </div>
              </a>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          
          {/*Dek view  */}
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className=" md:hidden items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                <li className="text-black hover:text-indigo-200">
                  <a href="javascript:void(0)">HOME</a>
                </li>
                <li className="text-black hover:text-indigo-200">
                  <a href="javascript:void(0)">WORK</a>
                </li>
                <li className="text-black hover:text-indigo-200">
                  <a href="javascript:void(0)">NEWS</a>
                </li>
                <li className="text-black hover:text-indigo-200">
                  <a href="javascript:void(0)">Post Details</a>
                </li>
                
              </ul>
              <ul>
              <li className="text-black hover:text-indigo-200">
                  <a href="javascript:void(0)" className="font-bold text-2xl">call us: +91 982-6906-988</a>
                </li>
              </ul>

              <div className=" md:hidden mt-3 space-y-2 lg:hidden md:inline-block">
                <div className="flex gap-10">
                  <Link to=" ">
                    <AiFillLinkedin className="text-3xl" />
                  </Link>

                  <Link to=" ">
                    <AiFillFacebook className="text-3xl" />
                  </Link>
                  <Link to=" ">
                    <AiFillTwitterSquare className="text-3xl" />
                  </Link>
                </div>
              </div>


            </div>
          </div>
          <div className="hidden space-x-2 md:inline-block">
            <div className="flex gap-10">
              <Link to=" ">
                <AiFillLinkedin className="text-3xl" />
              </Link>

              <Link to=" ">
                <AiFillFacebook className="text-3xl" />
              </Link>
              <Link to=" ">
                <AiFillTwitterSquare className="text-3xl" />
              </Link>
            </div>

          </div>
          <div className="md:block hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
