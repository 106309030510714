import React from "react";
import about from "../images/About.jpg";
const About = () => {
  return (
    <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:my-10 my-5 md:flex md:px-8">
      <div className="flex gap-20 md:flex-row flex-col-reverse">
        <div className="md:mx-20 mx-5 flex flex-col gap-10">
          <div className="font-bold text-lg text-textYellow">ABOUT US</div>
          <div className="font-bold text-5xl">We Are The Leader In The Architectural</div>
          <div className="leading-8">
            For each project we establish relationships with partners who we
            know will help us create added value for your project. As well as
            bringing together the public and private sectors, we make
            sector-overarching links to gather knowledge and to learn from each
            other
          </div>
          <div className="flex gap-5">
            <h1 className="font-bold text-7xl border-2 border-textYellow md:p-5 p-2"> 26 </h1>
            <h4 className="w-20 text-lg">Years Experience Working </h4>
           </div>
        </div>
        <div className="flex items-center justify-center">
          <img src={about} alt="" className="md:w-[1280px] md:h-[420px] w-96 h-96"/>
        </div>
      </div>
    </div>
  );
};

export default About;
