import React from "react";
import {FaFacebookF,FaTwitter,FaLinkedinIn, FaYoutube} from 'react-icons/fa'
import Logo from '../images/Group 3844.png'
const Footer = () => {
  return (
    <div>
      <div className="bg-blue-900 text-white md:py-10 py-5">
        <div className="flex md:flex-row flex-col gap-20 items-center justify-center">
          <div className=" md:w-[400px] w-96 md:px-10 px-10">
            <ul>
              <li className="font-bold text-2xl">About</li>
              <li>
                CERBOSYS is a software development company that provides
                customized and creative services for online businesses. The
                services we provide have been instrumental in helping shape
                several start-up companies by meeting their changing needs.
              </li>
            </ul>
          </div>
          <div>
          <ul>
              <li className="font-bold text-2xl">Address</li>
              <li>
              Address: 15-16 Lakshya Vihar Indore
              </li>
              <li>
              Tel: 0731 499 3444
              </li>
              <li>
              website: www.cerbosys.com
              </li>
              <br />
              <br />
            </ul>

          </div>
          <div>
            <div>
                <img src={Logo} alt="" />
            </div>
           <ul className="flex gap-5">
            <li><FaFacebookF/></li>
            <li><FaTwitter/></li>
            <li><FaLinkedinIn/></li>
            <li><FaYoutube/></li>
           </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
