import React from 'react'

const Home = () => {
  return (
    <div >
        <div className="selection:bg-Green  selection:text-white mx-auto w-full md:h-[35rem]  h-96 py-10 px-4 bg-Home bg-cover md:bg-top bg-center">
        {/* Content Section */}
        <div className="flex flex-col justify-center w-96  text-justify  items-center md:h-[30rem] h-96 mx-auto">
          <h1 className="text-white uppercase font-bold py-2 md:text-4xl  text-xl mb-3">
          Realestate <br /> And <br /> construction
          </h1>
          <p className=" justify-center text-xs  text-white font-subheading my-5   text-justify   md:text-lg   max-w-fit">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
          <div className='uppercase hover:bg-white hover:duration-500 duration-500  hover:text-black border-2 px-8 py-2 text-white'>
            see more 
          </div>
        </div>
        {/* Content Section Ends*/}
      </div>
    </div>
  )
}

export default Home