import React from "react";
import brand from "../images/brand.png";
import {
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="">
        <div className="my-10 md:mx-20 mx-10">
          <hr className="w-full" />
        </div>
        {/* footer  */}
        <div className="bg-yellow-100 my-10">
<div className="md:mx-20 mx-10 md:py-10 py-5">

  
        
        <div className="flex  md:flex-row flex-col justify-around">
          <div className="flex gap-5">
            <div className="mt-2">
              <img src={brand} alt="Logo" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-black">Cerbosys</h2>
              <small>architecture</small>
            </div>
          </div>
          <div>
            <ul>
              <li>
              © Cerbosys Technologies Inc.
              </li>
              <li>
              All rights reserved
              </li>
            </ul>

           
          </div>
          <div>
            <ul>
              <li> Site Map</li>
              <li>Term & Conditions</li>
              <li>Privacy Policy</li>
              <li>Help</li>
              <li>Affiliatep</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Our Location</li>
              <li>Career</li>
              <li>About</li>
              <li>Contact</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>ENG</li>
              <li>FRA</li>
              <li>GER</li>
            </ul>
          </div>
          <div>
            <ul className="flex flex-row gap-5">
              <li>
                <Link to=" ">
                  <AiFillLinkedin className="text-3xl" />
                </Link>
              </li>
              <li>
                <Link to=" ">
                  <AiFillFacebook className="text-3xl" />
                </Link>
              </li>
              <li>
                <Link to=" ">
                  <AiFillTwitterSquare className="text-3xl" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        </div>
      </div></div>
    </>
  );
};

export default Footer;
