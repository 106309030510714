import React from "react";
import icon from '../images/Group 8295.png'
const Navbar = () => {
 
  return (
    <div>
      <div class="antialiased bg-gradient-to-r from-pink-300 via-blue-300 to-indigo-400">
        <div>
          <nav class="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg text-gray-700 bg-white">
            <div>
              <img src={icon} alt="" />
            </div>
            <a href="">
              <svg
                xmlns="<http://www.w3.org/2000/svg>"
                id="menu-button"
                class="h-6 w-6 cursor-pointer md:hidden block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </a>
            <div
              class="hidden w-full md:flex md:items-center md:w-auto md:mx-10 mx-5 "
              id="menu"
            >
              <ul
                class="
              text-base text-gray-700
              pt-4
              md:flex
              md:justify-between
              md:pt-0"
              >
                <li>
                  <a class="md:p-4 py-2 block hover:text-blue-400" href="">
                    Home
                  </a>
                </li>
                <li>
                  <a class="md:p-4 py-2 block hover:text-blue-400" href="">
                    Pricing
                  </a>
                </li>
                <li>
                  <a class="md:p-4 py-2 block hover:text-blue-400" href="">
                    House
                  </a>
                </li>
                <li>
                  <a class="md:p-4 py-2 block hover:text-blue-400" href="">
                    Contact us
                  </a>
                </li>
                <li>
                  <a class="md:p-4 py-2 block hover:text-blue-400" href="">
                   About 
                  </a>
                </li>
                <li>
                  <a
                    class="md:p-4 py-2 block hover:text-blue-400 "
                    href=""
                  >
                       Login
                  </a>
                </li>
                <li>
                  <a
                    class="md:p-4 py-2 block hover:text-blue-400"
                    href=""
                  >
                    Sign Up
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
