import React from "react";
import fb from '../images/fb.png'
import twitter from '../images/twitter.png'
import circle from '../images/circle.png' 
const Footer = () => {
  return (
    <div>
      <div className="bg-black text text-orange-200 md:py-20 px-10">
        <div className="flex justify-around gap-10 md:flex-row flex-col">
          <div>
            <ul className="flex flex-col gap-5 text-xl">
              <li>Flawles Hotel</li>
              <li>Address: 15-16 Lakshya Vihar Indore</li>
              <li>
                <hr />
              </li>
             <a href="http://cerbosys.com/" target="_blank" rel="noopener noreferrer">
               <li>http://www.cerbosys.com/</li></a>
            </ul>
          </div>
     
          <div>
            <ul className="flex flex-col gap-5 text-xl">
              <li>CONTACT</li>
              <li className="flex gap-10">
                <div>TELEPHONE</div>

                <div>0731 499 3444</div>
              </li>
              <hr className="text-orange-200" />
              <li className="flex gap-10">
                <div>FAX</div>

                <div className="md:ml-20 ml-2">0731 499 3444</div>
              </li>
              <hr className="text-orange-200" />

              <li className="flex gap-10">
                <div>RESERVATIONS</div>

                <div>0731 499 3444</div>
              </li>
              <hr className="text-orange-200" />

            </ul>
          </div>

          <div>
            <ul className="flex flex-col gap-5 text-xl">
              <li>NEWSLETTER</li>
              <li>
               
                Sign up for our newsletter, and we’ll <br /> keep you updated on all
                events at Flawles!
              </li>
            </ul>
          </div>
        </div>
{/* icons  */}
<div className="flex gap-10 justify-center items-center md:my-10">

<div className="text-2xl font-bold">
  Follow Use 
</div>
<div>
  <ul className="flex gap-5">
    <li>
      <img src={fb} alt="" />
    </li>
    <li>
    <img src={circle} alt="" />

    </li>
    <li>
    <img src={twitter} alt="" />

    </li>
  </ul>
</div>

</div>

      </div>
    </div>
  );
};

export default Footer;
