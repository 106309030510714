import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineArrowRight } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import {AiFillStar} from 'react-icons/ai'
import Clint1 from "../images/clients/1-92x92.jpg";
import Clint2 from "../images/clients/2-92x92.jpg";
import One from '../images/partners/1.png'
import two from '../images/partners/2.png'
import three from '../images/partners/3.png'
import fore from '../images/partners/4.png'
import five from '../images/partners/5.png'
const Testmonial = () => {
  return (
    <div>
      <div className="font-bold text-3xl text-center ">
        From Great Our Clients
      </div>

      <div className="">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide className="">
            <div className="flex justify-center md:my-10 my-5   ">
              <div class="flex gap-10 justify-evenly  flex-wrap">
                <div class="flex gap-10 md:flex-row flex-col">
                  <div class=" h-[350px] flex rounded-lg bg-textYellow justify-center items-center">
                    <div class="w-96 ">
                      <div class="content relative rounded-md shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900">
                        <i class="mdi mdi-format-quote-open mdi-48px text-primary-600"></i>
                        <p class="text-slate-500">
                          "Sed elit quam, iaculis sed semper sit amet udin vitae nibh. at magna akal
                           semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup."
                        </p>
                        <ul class="list-none text-center mb-0 text-warning-400 flex gap-5 justify-center mt-3">
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                         
                        </ul>
                      </div>

                      <div class="text-center mt-5">
                        <img
                          src={Clint1}
                          class="h-14 w-14 rounded-full shadow-md mx-auto"
                          alt=""
                        />
                        <h6 class="mt-2 fw-semibold">Adam Stone</h6>
                        <span class="text-slate-400 text-sm">
                        CEO at Google INC
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class=" h-[350px] flex bg-textYellow rounded-lg justify-center items-center">
                    <div class="w-96">
                      <div class="content relative rounded-md shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900">
                        <i class="mdi mdi-format-quote-open mdi-48px text-primary-600"></i>
                        <p class="text-slate-400">
                          "Sed elit quam, iaculis sed semper sit amet udin vitae nibh. at magna akal semperFusce
                           commodo molestie luctus.Lorem ipsum Dolor tusima olatiup."
                        </p>
                        <ul class="list-none text-center mb-0 text-warning-400 flex gap-5 justify-center mt-3">
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                         
                        </ul>
                      </div>

                      <div class="text-center mt-5">
                        <img
                                                 src={Clint2}
                          class="h-14 w-14 rounded-full shadow-md mx-auto"
                          alt=""
                        />
                        <h6 class="mt-2 fw-semibold">Anabella Kleva</h6>
                        <span class="text-slate-400 text-sm">
                        Managerment at Envato
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="">
            <div className="flex justify-center md:my-10 my-5   ">
              <div class="flex gap-10 justify-evenly  flex-wrap">
                <div class="flex gap-10 md:flex-row flex-col">
               

                  <div class=" h-[350px] flex bg-textYellow rounded-lg justify-center items-center">
                    <div class="w-96">
                      <div class="content relative rounded-md shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900">
                        <i class="mdi mdi-format-quote-open mdi-48px text-primary-600"></i>
                        <p class="text-slate-400">
                          "Sed elit quam, iaculis sed semper sit amet udin vitae nibh. at magna akal semperFusce
                           commodo molestie luctus.Lorem ipsum Dolor tusima olatiup."
                        </p>
                        <ul class="list-none text-center mb-0 text-warning-400 flex gap-5 justify-center mt-3">
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                         
                        </ul>
                      </div>

                      <div class="text-center mt-5">
                        <img
                                                 src={Clint2}
                          class="h-14 w-14 rounded-full shadow-md mx-auto"
                          alt=""
                        />
                        <h6 class="mt-2 fw-semibold">Anabella Kleva</h6>
                        <span class="text-slate-400 text-sm">
                        Managerment at Envato
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class=" h-[350px] flex rounded-lg  bg-textYellow justify-center items-center">
                    <div class="w-96 py-10">
                      <div class="content relative rounded-md shadow dark:shadow-gray-700 m-2 p-6 bg-white dark:bg-slate-900">
                        <i class="mdi mdi-format-quote-open mdi-48px text-primary-600"></i>
                        <p class="text-slate-500">
                          "Sed elit quam, iaculis sed semper sit amet udin vitae nibh. at magna akal
                           semperFusce commodo molestie luctus.Lorem ipsum Dolor tusima olatiup."
                        </p>
                        <ul class="list-none text-center mb-0 text-warning-400 flex gap-5 justify-center mt-3">
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                          <li class="inline">
                            <i >
                              <AiFillStar className="text-red-500"/>
                            </i>
                          </li>
                         
                        </ul>
                      </div>

                      <div class="text-center mt-5">
                        <img
                          src={Clint1}
                          class="h-14 w-14 rounded-full shadow-md mx-auto"
                          alt=""
                        />
                        <h6 class="mt-2 fw-semibold">Adam Stone</h6>
                        <span class="text-slate-400 text-sm">
                        CEO at Google INC
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      
      </div>
      <div className="flex justify-center">



      <div class="flex justify-evenly my-10 md:flex-row flex-col gap-10 ">

            <div class="" data-aos="fade-up">
              <img alt="" src={One}/>
            </div>
            <div class="" data-aos="fade-up">
              <img alt="" src={two}/>
            </div>
            <div class="" data-aos="fade-left">
              <img alt="" src={three}/>
            </div>
            <div class="" data-aos="fade-down">
              <img alt="" src={fore} />
            </div>
            <div class="" data-aos="fade-down">
              <img alt="" src={five} />
            </div>
          </div>
       </div>
    </div>
  );
};

export default Testmonial;
