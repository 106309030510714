import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Componets/Navbar";
import Home from "./Componets/Home";
import Forms from "./Componets/Forms";
import Footer from "./Componets/Footer";
import Contet from "./Componets/Contet";

const Hotels = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>RealEstate || Hotel </title>
      </Helmet>

      <div>
        <Navbar />
        <Home />
        <Forms />

        <Contet />
        <Footer />
      </div>
    </div>
  );
};

export default Hotels;
