import React from "react";

const Forms = () => {
  return (
    <div>
      <div className="  justify-center flex ">
        <div className="flex md:flex-row flex-col gap-10  -mt-10 shadow-2xl justify-around md:w-[1000px] bg-white rounded-sm shadow-6xl md:px-5 px-10 md:py-5 py-2">
          <div>
            <input type="text" 
            placeholder="Serach Your Categories"
            className="md:px-20 px-10 md:py-2 py-2 border-1 outline-1 outline-double border-black"/>
          </div>
          <div>
            <input type="text" 
            placeholder="Location"
            className="md:px-20 px-10 md:py-2 py-2 border-1 outline-1 outline-double border-black" />
          </div>
          <div className="bg-amber-600 md:px-10 px-10 md:py-2 py-2 uppercase text-center">
            search
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forms;
