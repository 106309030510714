import React from "react";

const Hero = () => {
  return (
    <div>
      {/* Header start  */}
      <div className="selection:bg-Green selection:text-white mx-auto w-full md:h-[40rem]  h-[30rem] py-10 px-4 md:bg-propties bg-blue-100 bg-cover md:bg-top bg-center">
        {/* Content Section */}
        <div className="flex flex-col justify-center md:text-left text-center items-left md:h-[30rem] md:ml-20 ml-5 h-96 mx-auto gap-10">
          <h1 className="text-white uppercase font-bold py-2 md:text-6xl text-3xl mb-3">
            <h1 className="text-blue-800  font-bold ">Modern </h1>
            <h1 className="text-blue-500 font-bold ">Apartment </h1>
            <h1 className="text-blue-500 font-bold "> House </h1>
          </h1>
          <p className=" text-left text-black  text-lg font-subheading   md:text-2xl  md:w-[980px] max-w-fit">
            It is a long established fact that a reader will be distracted by{" "}
            <br /> the readable content of
          </p>
          <div>
            <button
              type="submit"
              className="text-black md:px-5 hover:bg-blue-600 hover:text-white px-5 md:py-2 py-2  border-2 border-blue-600 rounded-2xl "
            >
              Read More
            </button>
          </div>
        </div>
        {/* Content Section Ends*/}
      </div>

      {/* Header End  */}
    </div>
  );
};

export default Hero;
