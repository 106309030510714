import React from "react";
import S1 from "../images/s-1.jpg";
import S2 from "../images/s-2.jpg";
import S3 from "../images/s-3.jpg";
import S4 from "../images/s-4.jpg";
import S5 from "../images/s-5.jpg";
import S6 from "../images/s-6.jpg";
const Sale = () => {
  return (
    <div>
      <div>
        {/* Heders start  */}
        <div className="flex flex-col items-center md:mx-2 mx-5">
          <div className=" divide-x-8  divide-blue-800   ">
            <h1 className="divide-x-4  divide-red-700"> </h1>
            <h1 className="font-bold text-4xl  mx-2">House For Sale </h1>
          </div>
          <div></div>
          <div>
            There are many variations of passages of Lorem Ipsum available, but
            the
          </div>
        </div>

        {/* Heders END  */}

        {/* homes Content        */}

        <div className="flex md:flex-row  justify-center flex-col flex-wrap gap-10 md:mx-5 mx-5 md:my-5 my-5">
          <div className=" flex flex-col gap-5 md:w-[400px] ">
            <div>
              <img
                src={S1}
                alt=""
                className="md:w-[400px] md:h-[300px] w-96 h-96"
              />
            </div>
            <div> APERTMENTS HOUSE</div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but
            </div>
          </div>
          <div className=" flex flex-col gap-5 md:w-[400px] ">
            <div>
              <img
                src={S2}
                alt=""
                className="md:w-[400px] md:h-[300px] w-96 h-96"
              />
            </div>
            <div> APERTMENTS HOUSE</div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but
            </div>
          </div>
          <div className=" flex flex-col gap-5 md:w-[400px] ">
            <div>
              <img
                src={S3}
                alt=""
                className="md:w-[400px] md:h-[300px] w-96 h-96"
              />
            </div>
            <div> APERTMENTS HOUSE</div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but
            </div>
          </div>
          <div className=" flex flex-col gap-5  md:w-[400px]">
            <div>
              <img
                src={S4}
                alt=""
                className="md:w-[400px] md:h-[300px] w-96 h-96"
              />
            </div>
            <div> APERTMENTS HOUSE</div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but
            </div>
          </div>
          <div className=" flex flex-col gap-5  md:w-[400px]">
            <div>
              <img
                src={S5}
                alt=""
                className="md:w-[400px] md:h-[300px] w-96 h-96"
              />
            </div>
            <div> APERTMENTS HOUSE</div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but
            </div>
          </div>
          <div className=" flex flex-col gap-5  md:w-[400px]">
            <div>
              <img
                src={S6}
                alt=""
                className="md:w-[400px] md:h-[300px] w-96 h-96"
              />
            </div>
            <div> APERTMENTS HOUSE</div>
            <div>
              There are many variations of passages of Lorem Ipsum available,
              but
            </div>
          </div>
        </div>

        {/* homes Content        */}

        <div className="flex justify-center items-center md:my-10 my-10 ">
            <button className="border rounded-2xl border-blue-900 text-blue-800 hover:bg-blue-800 hover:text-white md:px-5 px-5 py-2">Find More </button>
        </div>
      </div>
    </div>
  );
};

export default Sale;
