import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineArrowRight } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import Project1 from "../images/projects/1-426x574.jpg";
import Project2 from "../images/projects/2-426x574.jpg";
import Project3 from "../images/projects/3-426x574.jpg";
import Project4 from "../images/projects/4-426x574.jpg";
import Project5 from "../images/projects/5-426x574.jpg";
import Project6 from "../images/projects/6-426x574.jpg";
import Project7 from "../images/projects/7-426x574.jpg";
import Project8 from "../images/projects/8-426x574.jpg";
import { Link } from "react-router-dom";

const LatestProjects = () => {
  return (
    <>
      <div className="">
        <div className=" md:mx-20 mx-10 ">
          <div className="flex gap-10 justify-between cursor-pointer md:flex-row flex-col   ">
            <div className="font-bold text-3xl">Latest Projects</div>
            <div>
              <ul className=" flex gap-10">
                <li className="text-textYellow">All</li>
                <li>Building</li>
                <li>Interior &Exterior</li>
              </ul>
            </div>
            <div className="text-textYellow">View All Projects</div>
          </div>

          {/* slider start  */}

          <div className="">
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide className="">

        <div className="flex justify-center md:my-10 my-5   ">
              <div class="flex gap-10 justify-evenly  flex-wrap">
              <div class="flex gap-10 md:flex-row flex-col">
                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project1} alt="Flower and sky"/>

                  <div  data-aos="fade-up" class="absolute bottom-0 left-0 px-6 py-4">
                    <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">Triangle<br/>Concrete House<br/>On Lake </h4>
                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                </div>

                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project2} alt="Flower and sky"/>

                  <div  data-aos= "fade-down" class="absolute bottom-0 left-0 px-6 py-4">
                    <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">Ocean<br/>Museum<br/>Italy</h4>
                    <p class="leading-normal text-gray-100">See project</p>
                  </div>
                </div>

                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project3} alt="Flower and sky"/>

                  <div data-aos= "fade-right" class="absolute bottom-0 left-0 px-6 py-4">
                  <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">
                  Milko<br/>Co-Working<br/>Building
                    </h4>

                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
         
        </SwiperSlide>
        <SwiperSlide className="">

        <div className="flex justify-center md:my-10 my-5   ">
              <div class="flex gap-10 justify-evenly  flex-wrap">
              <div class="flex gap-10 md:flex-row flex-col">
                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project4} alt="Flower and sky"/>

                  <div data-aos= "fade-left" class="absolute bottom-0 left-0 px-6 py-4">
                  <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">
                  Redesign<br/>Interior For<br/>Villa
                    </h4>
                    <p class="leading-normal text-white">
                    See project</p>
                  </div>
                </div>

                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project5} alt="Flower and sky"/>

                  <div  data-aos= "fade-up" class="absolute bottom-0 left-0 px-6 py-4">
                  <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">
                  Wooden<br/>Hozirontal<br/>Villa
                    </h4>
                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                </div>

                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project6} alt="Flower and sky"/>

                  <div data-aos= "fade-down" class="absolute bottom-0 left-0 px-6 py-4">
                  <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">
                  Small<br/>House Near<br/>Wroclaw
                    </h4>
                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
         
        </SwiperSlide>
        <SwiperSlide className="">

        <div className="flex justify-center md:my-10 my-5   ">
              <div class="flex gap-10 justify-evenly  flex-wrap">
              <div class="flex gap-10 md:flex-row flex-col">
                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[350px] md:h-[400px]" src={Project7} alt="Flower and sky"/>

                  <div data-aos= "fade-up" class="absolute bottom-0 left-0 px-6 py-4">
               
                  <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">
                  Bellecomde<br/>Holiday<br/>Residence
                    </h4>
                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                  </div>
                </div>

                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[350px] md:h-[400px]" src={Project8} alt="Flower and sky"/>

                  <div data-aos= "fade-down" class="absolute bottom-0 left-0 px-6 py-4">
                  <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">
                   Cubic<br/>Inter Mesuem<br/>In Rome
                    </h4>
                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                </div>

                <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                  <img class="object-cover md:w-[400px] md:h-[400px]" src={Project1} alt="Flower and sky"/>

                  <div data-aos= "fade-right" class="absolute bottom-0 left-0 px-6 py-4">
                    <h4 class="mb-3  tracking-tight text-white font-bold text-3xl">Triangle<br/>Concrete House<br/>On Lake </h4>
                    <p class="leading-normal text-gray-100">
                    See project</p>
                  </div>
                </div>

              </div>
            </div>
        
         
        </SwiperSlide>
  
      </Swiper>

         
          {/* slider eND  */}
        </div>

      

      </div>
 
      </div>
    </>
  );
};

export default LatestProjects;
