import React from "react";
import U1 from "../images/u-1.png";
import U2 from "../images/u-2.png";
import U3 from "../images/u-3.png";
import U4 from "../images/u-4.png";

const Choose = () => {
  return (
    <>
      <div className="bg-blue-900 md:py-10 py-10 ">
        {/* heding Start  */}
        <div className="flex flex-col items-center md:mx-2 mx-5">
          <div className=" divide-x-8  divide-white   ">
            <h1 className="divide-x-4  divide-red-700"> </h1>
            <h1 className="font-bold text-4xl  mx-2 text-white">
              Our Pricing{" "}
            </h1>
          </div>
        </div>
        {/* heding  END */}
        <div className="flex flex-col md:flex-row items-center flex-wrap justify-center gap-20 md:my-10 my-10">
          <div className="flex flex-col text-white font-bold text-2xl">
            <div>
              <img src={U1} alt="" />
            </div>
            <div>1000+</div>
            <div>Years of House</div>
          </div>
          <div className="flex flex-col text-white font-bold text-2xl">
            <div>
              <img src={U2} alt="" />
            </div>
            <div>1000+</div>
            <div>Years of House</div>
          </div>
          <div className="flex flex-col text-white font-bold text-2xl">
            <div>
              <img src={U3} alt="" />
            </div>
            <div>1000+</div>
            <div>Years of House</div>
          </div>
          <div className="flex flex-col text-white font-bold text-2xl">
            <div>
              <img src={U4} alt="" />
            </div>
            <div>1000+</div>
            <div>Years of House</div>
          </div>
        </div>
        <div className="flex justify-center items-center md:mt-10 mt-10 ">
          <button
            className="border rounded-2xl
          bg-white text-black 
              md:px-5 px-5 py-2"
          >
            Get A Quote{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Choose;
