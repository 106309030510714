import React, { useEffect } from "react";
import Navbar from "./Componets/Navbar";
import Home from "./Componets/Home";
// Animation package
import Aos from "aos";
import "aos/dist/aos.css";
import About from "./Componets/About";
import LatestProjects from "./Componets/LatestProjects";
import Testmonial from "./Componets/Testmonial";
import LatestNews from "./Componets/LatestNews";
import Footer from "./Componets/Footer";
import { Helmet } from "react-helmet";
import favicon from "./images/favicon.png";
const Arcitecture = () => {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 100,
      disable: "mobile",
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>RealEstate || architectural-design </title>
      </Helmet>

      <div className="">
        <Navbar />
        <Home />
        <About />
        <LatestProjects />
        <Testmonial />
        <LatestNews />
        <Footer />
      </div>
    </>
  );
};

export default Arcitecture;
