import React from 'react'
import Hero from './Componts/Hero'
import Navbar from './Componts/Navbar'
import { Helmet } from 'react-helmet'
import favicon from "./images/Cerbosys.png";
import Forms from './Componts/Forms';
import About from './Componts/About';
import Sale from './Componts/Sale';
import Pricing from './Componts/Pricing';
import Deal from './Componts/Deal';
import Choose from './Componts/Choose';
import Customer from './Componts/Customer';
import Footer from './Componts/Footer';
const Propertie = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link rel="icon" href="./images/Cerbosys.png" />
        <title>RealEstate || Propertie </title>
      </Helmet>
    <div>
        <Navbar/>
        <Hero/>
        <Forms/>
        <About/>
        <Sale/>
        <Pricing/>
        <Deal/>
        <Choose/>
        <Customer/>
        <Footer/>
    </div>
    </div>
  )
}

export default Propertie